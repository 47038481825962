.list-view-products {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.list-product-card {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
}

.list-product-card-inner {
    display: flex;
    width: 100%;
    padding: 30px;
    border: 1px solid $color-gray-lite;
    border-radius: $radius-default;
    transition: $transition-primary;

    &:hover {
        border-color: $color-white;
        box-shadow: 2.5px 4.33px 50px 5px rgba(11, 28, 42, 0.15);
        z-index: 1;
    }

    .product-card-left {
        align-self: flex-start;
    }

    .product-card-right {
        flex-grow: 1;
        margin: -5px 0 -8px 30px;
    }

    .product-image {
        position: relative;
        height: 180px;
        width: 180px;

        .image-placeholder {
            height: 85px;
            width: 85px;
        }
    }

    .product-card-countdown {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: $transition-primary;

        &.is-countdown {
            .countdown-section {
                width: auto;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .countdown-amount {
                height: 38px;
                width: 38px;
                color: $color-white;
                margin: 0;
                background: $color-default-transparent;
                background: var(--color-primary-transparent);
            }

            .countdown-period {
                display: none;
            }
        }
    }

    .product-name {
        display: inline-block;
        margin-bottom: 10px;
        align-items: center;
        z-index: 1;

        > h6 {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            max-height: 48px;
            display: block;
            display: -webkit-box;
            color: $color-black;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .product-rating {
        margin-bottom: 14px;

        .rating-count {
            font-size: 13px;
            line-height: 15px;
            margin: 0 0 0 7px;
        }
    }

    .product-price {
        font-size: 18px;
        margin-bottom: 13px;

        .previous-price {
            font-size: 14px;
        }
    }

    .btn-add-to-cart {
        font-size: 13px;
        margin-bottom: 9px;
        padding: 7px 30px 6px;

        > i {
            font-size: 15px;
            margin: 5px 8px 0 0;
            vertical-align: top;
        }
    }

    .product-card-actions {
        .btn {
            padding: 0;
            color: $color-gray;

            &:hover {
                color: $color-default;
                color: var(--color-primary);

                > i {
                    color: $color-gray;
                }
            }

            > i {
                margin-right: 4px;
            }
        }

        .btn-wishlist {
            margin-right: 25px;
        }

        .btn-wishlist,
        .btn-compare {
            &.added {
                &:hover {
                    > i {
                        color: $color-default;
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .list-product-card {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: $sm) {
    .list-product-card-inner {
        flex-direction: column;

        .product-card-left {
            width: 100%;
            margin-bottom: 20px;

            .product-image {
                width: 100%;
            }
        }

        .product-card-right {
            margin-left: 0;
        }
    }
}
