.loading {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $color-white-transparent;
        border-radius: $radius-default;
        z-index: 2;
    }

    &:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 3px solid $color-default;
        border: 3px solid var(--color-primary);
        border-top-color: transparent !important;
        z-index: 3;
        animation: loader-spin 800ms infinite linear;
    }
}

.btn-loading {
    color: transparent !important;

	&:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        height: 16px;
        width: 16px;
        border: 2px solid $color-white;
        border-radius: 50%;
        border-top-color: transparent !important;
        animation: loader-spin 800ms infinite linear;
    }
}

@keyframes loader-spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes tab-loader {
    0% {
        left: 0;
        width: 20px;
    }

    50% {
        left: calc(100% - 20px);
        width: 20px;
    }

    100% {
        left: 0;
        width: 20px;
    }
}
