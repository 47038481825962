.shopping-cart {
    .table-responsive {
        border: 1px solid $color-gray-lite;
        border-radius: $radius-default;
        margin-bottom: 25px;
    }
}

.shopping-cart-table {
    margin-bottom: 10px;

    thead {
        th {
            white-space: nowrap;

            &:last-child {
                width: 70px;
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }
        }
    }

    tbody {
        td {
            vertical-align: middle;

            &:last-child {
                width: 70px;
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }
        }
    }

    .btn-remove {
        line-height: 20px;
    }

    .product-image {
        height: 60px;
        width: 60px;
        border-radius: $radius-default;
        overflow: hidden;

        .image-placeholder {
            height: 40px;
            width: 40px;
        }
    }

    .product-name {
        display: inline-block;
        color: $color-black;

        &:hover {
            color: $color-default;
            color: var(--color-primary);
        }
    }

    .product-options {
        margin: 4px 0 1px;
    }

    .product-price {
        color: $color-black;
    }

    .number-picker {
        position: relative;
        width: 57px;

        .input-quantity {
            height: auto;
            padding: 0;
            text-align: center;
            border: none;
        }

        .btn-number {
            position: absolute;
            padding: 0;
            top: 50%;
            background: $color-white;
            transform: translateY(-50%);

            &:hover {
                color: $color-default;
                color: var(--color-primary);
            }

            &[disabled] {
                opacity: 1;

                > i {
                    opacity: 0.5;
                }

                &:hover {
                    color: $color-black;
                }
            }
        }

        .btn-minus {
            left: 0;
        }

        .btn-plus {
            right: 0;
        }
    }
}

.rtl {
    .shopping-cart-table {
        .number-picker {
            .btn-number {
                transform: translateY(-50%) rotateY(180deg) #{'/*rtl:ignore*/'};
            }
        }
    }
}

@media screen and (min-width: 1281px) {
    .shopping-cart-table {
        td {
            > label {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .shopping-cart-table {
        thead {
            display: none;
        }

        tr {
            &:first-child {
                td {
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        padding-top: 2px;
                    }
                }
            }
        }
        tbody {
            td {
                vertical-align: top;

                &:not(:first-child):not(:last-child) {
                    display: block;
                }

                &:nth-child(2) {
                    padding: 20px 0 11px;
                }

                &:nth-child(3) {
                    padding: 2px 0;
                    white-space: nowrap;
                }

                &:nth-child(4) {
                    padding: 2px 0;
                    white-space: nowrap;
                }

                &:nth-child(5) {
                    padding: 2px 0 13px;
                    white-space: nowrap;
                }
            }
        }

        td {
            > label {
                width: 90px;
                min-width: 90px;
                margin-bottom: 0;
                padding-right: 5px;
                color: $color-gray;
                white-space: pre-wrap;
            }
        }

        .product-price {
            display: inline-flex;
        }

        .number-picker {
            display: inline-block;
        }
    }
}

@media screen and (max-width: $sm) {
    .shopping-cart-table {
        tbody {
            td {
                &:first-child {
                    padding: 20px;
                }

                &:last-child {
                    width: 50px;
                }

                > label {
                    width: 80px;
                    min-width: 80px;
                }
            }
        }
    }
}
