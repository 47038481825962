.sidebar-menu-wrap {
    position: fixed;
    left: -150px;
    top: 0;
    bottom: 0;
    width: 270px;
    background: $color-white;
    box-shadow: 2.5px 4.33px 40px 5px rgba(12, 31, 46, 0.1);
    opacity: 0;
    visibility: hidden;
    z-index: 200;
    transition: $transition-primary;

    &.active {
        left: 0;
        opacity: 1;
        visibility: visible;
    }

    .sidebar-menu-header {
        display: flex;
        padding: 15px 26px;
        align-items: center;
        justify-content: space-between;
        background: $color-default;
        background: var(--color-primary);

        > h4 {
            font-weight: 400;
            color: $color-white;
        }

        .sidebar-menu-close {
            display: flex;
            cursor: pointer;
            opacity: 0.8;
            transition: $transition-default;

            &:hover {
                opacity: 1;
            }

            > i {
                font-size: 18px;
                color: $color-white;
            }
        }
    }

    .sidebar-menu-tab {
        border-bottom: none;

        .nav-item {
            width: 50%;
        }

        &.nav-tabs {
            .nav-link {
                padding: 8px;
                color: $color-gray-dark;
                text-align: center;
                background: $color-white-lite;
                border: none;
                border-radius: 0;

                &.active {
                    font-weight: 400;
                    color: $color-black;
                    background: $color-white;
                    cursor: default;
                }

                &:after {
                    content: none;
                }
            }
        }
    }

    > .tab-content {
        position: absolute;
        left: 0;
        top: 100px;
        right: 0;
        bottom: 0;
    }
}

.sidebar-menu {
    margin: 15px 0;

    > li {
        cursor: pointer;

        &:hover {
            > .menu-item {
                color: $color-default;
                color: var(--color-primary);

                > .menu-item-icon {
                    color: $color-default;
                    color: var(--color-primary);
                }
            }

            > i {
                color: $color-default;
                color: var(--color-primary);
            }
        }

        &.active {
            .menu-item {
                color: $color-default;
                color: var(--color-primary);

                &:after {
                    opacity: 1;
                    visibility: visible;
                }

                > .menu-item-icon {
                    color: $color-default;
                    color: var(--color-primary);
                }
            }

            > i {
                transform: rotate(90deg);
            }
        }

        > .menu-item {
            &:after {
                position: absolute;
                content: '';
                left: 0;
                top: 16px;
                height: 15px;
                width: 7px;
                background: $color-default;
                background: var(--color-primary);
                opacity: 0;
                visibility: hidden;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                transition: $transition-default;
            }

            > .menu-item-icon {
                font-size: 16px;
                color: $color-gray;
                margin: 1px 10px 0 0;
                transition: $transition-default;
            }
        }

        > ul {
            > li {
                > a {
                    max-width: 160px;
                }

                > ul {
                    > li {
                        > a {
                            max-width: 155px;
                        }
                    }
                }
            }
        }
    }

    li {
        &:hover {
            > a,
            > i {
                color: $color-default;
                color: var(--color-primary);
            }
        }

        a {
            line-height: 45px;
            max-width: 170px;
            display: inline-block;
            margin-left: 24px;
            color: $color-black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }

        > i {
            font-size: 12px;
            position: absolute;
            top: 18px;
            right: 30px;
            color: $color-gray;
            float: right;
            transition: $transition-default;
        }

        ul {
            display: none;
            margin-left: 10px;
        }
    }

    .dropdown {
        position: relative;

        &.active {
            > a {
                color: $color-default;
                color: var(--color-primary);
            }

            > i {
                color: $color-default;
                color: var(--color-primary);
                transform: rotate(90deg);
            }
        }
    }
}
