h1, h2, h3, h4, h5, h6, p, span {
    font-family: $primary-font;
}

h1 {
    font-size: 36px;
    line-height: 44px;
}

h2 {
    font-size: 28px;
    line-height: 36px;
}

h3 {
    font-size: 24px;
    line-height: 30px;
}

h4 {
    font-size: 20px;
    line-height: 28px;
}

h5 {
    font-size: 18px;
    line-height: 24px;
}

h6 {
    font-size: 15px;
    line-height: 18px;
}

p {
    font-size: 15px;
    line-height: 22px;
}
