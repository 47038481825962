.footer-bottom {
    margin-top: 60px;
    padding: 20px 0;
    border-top: 1px solid $border-color-lite;
}

.footer-text {
    line-height: 26px;
    color: $color-gray;

    a {
        font-weight: 500;
        color: $color-default;
        color: var(--color-primary);

        &:hover {
            color: $color-default-hover;
            color: var(--color-primary-hover);
        }
    }
}

.footer-payment {
    display: flex;
    justify-content: flex-end;

    > img {
        max-height: 30px;
        max-width: 100%;
    }
}

@media screen and (max-width: $md) {
    .footer-bottom {
        margin-top: 50px;
        padding-top: 13px;
    }

    .footer-text {
        text-align: center;
    }

    .footer-payment {
        margin-top: 15px;
        justify-content: center;
    }
}
