.product-search-right {
    width: calc(100% - 293px);

    .categories-banner {
        display: block;
        margin-bottom: 40px;

        &:before,
        &:after {
            content: none;
        }

        &:hover {
            > img {
                transform: scale(1);
            }
        }

        > img {
            width: 100%;
            border-radius: $radius-default;
        }
    }
}

@media screen and (max-width: 1400px) {
    .product-search-right {
        width: calc(100% - 276px);
    }
}

@media screen and (max-width: $lg) {
    .product-search-right {
        width: 100%;
        margin-top: 20px;
    }
}
