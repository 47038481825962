.custom-page-wrap {
    margin-top: 50px;
}

.custom-page-content {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 14px;
    }

    p {
        line-height: 23px;
        color: $color-gray;
    }

    ul, ol {
        padding-left: 16px;
        overflow: hidden;

        li {
            margin-bottom: 9px;
            color: $color-gray;
        }
    }

    b, strong, em {
        font-weight: 500;
    }

    a {
        color: $color-default;
        color: var(--color-primary);

        &:hover {
            color: $color-default-hover;
            color: var(--color-primary-hover);
        }
    }

    img {
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    iframe {
        border: none;
        max-width: 100%;
    }

    th {
        font-weight: 500;
    }

    td {
        vertical-align: top;
    }
}
