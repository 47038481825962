.header-column-left {
    display: flex;
    align-self: flex-start;
}

.sidebar-menu-icon-wrap {
    display: flex;
    height: 60px;
    padding-left: 15px;
    align-items: center;
    justify-content: center;

    .sidebar-menu-icon {
        display: flex;
        height: 30px;
        width: 35px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        &:hover {
            > span {
                width: 20px;
                background: $color-default;
                background: var(--color-primary);

                &:last-child {
                    width: 20px;
                }
            }
        }

        > span {
            height: 2px;
            width: 16px;
            margin-bottom: 4px;
            background: $color-black;
            transition: $transition-primary;

            &:nth-child(2) {
                width: 20px;
            }

            &:last-child {
                width: 12px;
                margin-bottom: 0;
            }
        }
    }
}

.header-logo {
    display: flex;
    height: 60px;
    width: 266px;
    padding: 0 15px;
    align-items: center;
    overflow: hidden;

    img {
        max-height: 100%;
        max-width: 100%;
    }

    h3 {
        color: $color-default;
        color: var(--color-primary);
    }

    svg {
        direction: ltr #{'/*rtl:ignore*/'};
        height: 60px;
        width: 245px;

        #logo_f_bg,
        #logo_f_text,
        #logo_f_text_inner {
            fill: $color-default;
            fill: var(--color-primary);
        }
    }
}

@media screen and (max-width: $xl) {
    .header-logo {
        width: 200px;
    }
}

@media screen and (min-width: 992px) {
    .sidebar-menu-icon-wrap {
        display: none;
    }
}

@media screen and (max-width: $lg) {
    .header-logo {
        h1 {
            font-size: 28px;
        }
    }
}

@media screen and (max-width: 391px) {
    .header-logo {
        width: 160px;
        padding-left: 5px;
    }
}
