.panel {
	margin-bottom: 50px;
	border-radius: $radius-default;

	&:last-child {
		margin-bottom: 0;
	}

	.panel-header {
		display: flex;
		padding: 20px 30px;
		background: $color-white-lite;
		align-items: center;
		justify-content: space-between;
		border: 1px solid $color-gray-lite;
		border-radius: $radius-default $radius-default 0 0;

		> a {
			color: $color-gray-dark;

			&:hover {
				color: $color-default;
                color: var(--color-primary);
			}
		}
	}

	.panel-body {
		border: 1px solid $color-gray-lite;
		border-top: none;
		border-radius: 0 0 $radius-default $radius-default;
	}
}
