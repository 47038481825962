.checkout-left {
    flex-grow: 1;
    padding-right: 15px;
}

.checkout-form {
    .account-details {
        margin-bottom: 14px;
    }

    .section-title {
        margin-bottom: 25px;
        padding-bottom: 13px;
        border-bottom: 1px solid $border-color-lite;
    }

    .create-an-account-form {
        .helper-text {
            font-size: 14px;
            display: block;
            margin: -4px 0 14px;
            color: $color-gray;
        }
    }

    .create-an-account-label,
    .ship-to-different-address-label {
        margin: 4px 0 7px;

        label {
            color: $color-black;
        }
    }

    .select-address {
        padding: 3px 0;

        .form-group {
            margin-bottom: 0;
        }

        .form-radio {
            margin-bottom: 9px;
        }

        label {
            > span {
                font-weight: 500;
                display: block;
                color: $color-black;

                &:not(:first-child) {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: $color-gray;
                }

                &:nth-child(2) {
                    margin-top: 5px;
                }
            }
        }

        .error-message {
            display: inline-flex;
            margin: 0 0 20px;
        }
    }

    .add-new-address-wrap {
        .btn-add-new-address {
            font-weight: 500;
            margin-bottom: 22px;
            padding: 5px 0;

            &:hover {
                color: $color-default;
                color: var(--color-primary);
            }
        }
    }

    .ship-to-different-address-form {
        padding-top: 14px;
    }

    .order-notes {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1280px) {
    .checkout-form {
        .order-notes {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: $lg) {
    .checkout-left {
        padding-right: 0;
    }
}
