.grid-view-products {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    > .col {
        display: flex;
        flex: 0 0 20%;
        max-width: 20%;
        margin-bottom: 30px;
    }

    .product-card {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 1700px) {
    .grid-view-products {
        > .col {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

@media screen and (max-width: 1350px) {
    .grid-view-products {
        > .col {
            flex: 0 0 33.33333333333333%;
            max-width: 33.33333333333333%;
        }
    }
}

@media screen and (max-width: $lg) {
    .search-result-middle {
        &.empty {
            .grid-view-products {
                padding-bottom: 0;
            }
        }
    }

    .grid-view-products {
        margin: 0 -8px;
        padding-bottom: 14px;

        > .col {
            flex: 0 0 25%;
            max-width: 25%;
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

@media screen and (max-width: 880px) {
    .grid-view-products {
        > .col {
            flex: 0 0 33.33333333333333%;
            max-width: 33.33333333333333%;
        }
    }
}

@media screen and (max-width: $md) {
    .grid-view-products {
        margin: 0 -8px;

        > .col {
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

@media screen and (max-width: 620px) {
    .grid-view-products {
        > .col {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
